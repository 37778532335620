@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Manrope:wght@200..800&display=swap');

*{
  margin: 0;
  padding:0;
  box-sizing:border-box;
  text-decoration: none;
  border: none;
  outline: none;
}
a{
  color:black
}

/* //luxenta download */
@font-face {
  font-family: 'Luxenta';  /* Custom name for the font */
  src: url('./pages//Assets//Fonts//luxenta.extra-bold.otf') format('opentype');  /* Path to your font file */
  font-weight: normal;
  font-style: normal;
}


.carousel-container {
  padding: 20px 0;
}

.carousel-container img {
  width: 100%;
  height: auto;
  padding: 0 10px;
}